import React from 'react'
import Application from './jsx/Application.jsx'
import { GlobalStateProvider } from './jsx/GlobalStateContext.jsx'
import { BrowserRouter } from 'react-router-dom'

function App () {
  return (
    <BrowserRouter>
      <GlobalStateProvider>
        <Application />
      </GlobalStateProvider>
    </BrowserRouter>
  )
}

export default App
