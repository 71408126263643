import '../css/main.css'
import EditReportIcon from '../icons/three_points.svg'

const HeaderWeekBox = () => {
  return (
        <div className="week-column-box" style={{ background: '#313133' }}>
            <div className="week-column-box-top" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)' }}>Дата</div>
            <div className="week-column-box-top" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)' }}>Проекты</div>
            <div className="week-column-box-top" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)' }}>Общее время отчета</div>
            <div className="week-column-box-top" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)' }}>Работа из офиса/дома</div>
            <div className="week-column-box-top" style={{ justifyContent: 'center' }}><img src={EditReportIcon}/></div>
        </div>
  )
}

export default HeaderWeekBox
