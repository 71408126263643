import '../css/main.css'
import editPencilIcon from '../icons/edit_pencil.svg'
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { getYear, getDate, getMonth, format } from 'date-fns'
import ProjectBox from './ProjectBox'
import { GlobalStateContext } from './GlobalStateContext'
import { getHourString } from './Helpers/TimeExtensions'

const MainWeekBox = ({ id, date, projects, workPlace, time, projectsList }) => {
  const { changeDay } = useContext(GlobalStateContext)
  const [tm, setTm] = useState('')
  const navigate = useNavigate()
  const [projectSet, setProjectSet] = useState(null)

  useEffect(() => {
    if (projects) {
      const uniqueProjects = Array.from(
        new Map(
          projects.map(project => [project.project_id, project])
        ).values()
      )
      setProjectSet(uniqueProjects)
    }
  }, [projects])

  useEffect(() => {
    setTm(getHourString(time))
  }, [])

  const GoToEditReport = () => {
    changeDay(date)
    navigate(`/write-report?date=${format(date, 'yyyy-MM-dd')}`)
  }

  const retrieveProjectName = (item) => {
    const projectItem = projectsList ? projectsList.find(project => project.id === item.project_id) : 'null'
    return projectItem ? projectItem.name : 'Проект не выбран'
  }

  return (
        <div className="column-box">
            <div className="row-box" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)' }}>{`${getDate(date).toString().padStart(2, '0')}.${(getMonth(date) + 1).toString().padStart(2, '0')}.${getYear(date)}`}</div>
            <div className="row-box" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)', columnGap: '4px', rowGap: '6px', display: 'flex', flexWrap: 'wrap' }}>
                { projectSet && projectSet.map((item, index) => (
                    <ProjectBox key={index} img={item.img_project} text={retrieveProjectName(item)} />
                ))}
            </div>
            <div className="row-box" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)' }}>{tm}</div>
            <div className="row-box" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)', paddingRight: '12px' }}>
                <p>{workPlace}</p>
            </div>
            <div className="row-box" style={{ justifyContent: 'center' }}><img src={editPencilIcon} onClick={GoToEditReport}/></div>
        </div>
  )
}

export default MainWeekBox
