import DeleteSelectItem from '../icons/icon_delete_select_item.svg'
import ArrowSelectIcon from '../icons/icon_select_arrow.svg'
import React, { useState, useEffect } from 'react'
import '../css/multiselect.css'
import { convertStaffPositionToString } from './Helpers/StaffExtensions'

const MultiSelectRole = ({ selectedPositions, availablePositions }) => {
  const [SelectedItems, setSelectedItems] = useState([])
  const [VisibleArray, setVisibleArray] = useState(false)
  const ArrayItems = availablePositions

  useEffect(() => {
    console.log(SelectedItems)
    selectedPositions(SelectedItems)
  }, [SelectedItems])

  const changeVisibleArray = () => {
    setVisibleArray(!VisibleArray)
  }

  const AddItemInArray = (item) => {
    if (!SelectedItems.includes(item)) {
      setSelectedItems((prevItems) => [...prevItems, item])
    }
  }

  const DeleteItemInArray = (item) => {
    setSelectedItems((items) => {
      return items.filter((itemArray) => itemArray !== item)
    })
  }

  return (
    <div className='main-multiselect-role'>
      <div className='picker-multiselect-role' onClick={changeVisibleArray}>
        {SelectedItems.length === 0 ? <p>Выберите должность</p> : ''}
        <div className='selected-items-role'>
          {SelectedItems.map((item, index) => (
            <div className='item-multiselect-role' key={index}>
              <p>{convertStaffPositionToString(item)}</p>
              <img
                src={DeleteSelectItem}
                onClick={(e) => {
                  e.stopPropagation()
                  DeleteItemInArray(item)
                }}
              />
            </div>
          ))}
        </div>
        <img className='expand-arrow' src={ArrowSelectIcon} />
      </div>
      {VisibleArray && (
        <div className='array-multiselect-items'>
          {ArrayItems.map((item, index) => (
            <div className='item-array-multiselect' key={index} onClick={() => AddItemInArray(item)}>
              <p>{convertStaffPositionToString(item)}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default MultiSelectRole
