/* eslint-disable array-callback-return */
import '../../css/detailed-staff-info.css'
import IconHome from '../../icons/icon_home.svg'
import IconOffice from '../../icons/icon_office.svg'
import IconNoReport from '../../icons/icon_no_report.svg'

const SummaryDayReportRightBar = ({ reportList, currentDay }) => {
  function sumHoursWork () {
    let resultHours = 0
    let resultMinutes = 0

    reportList.map(item => {
      item.item_reports.map(itemReport => {
        resultHours = resultHours + itemReport.hours_work
        resultMinutes = resultMinutes + itemReport.minutes_work
      })
    })

    return resultHours + Math.floor(resultMinutes / 60)
  }

  const calculateWorkHomeCount = () => {
    let count = 0
    reportList.map(item => {
      if (item.is_work_home) {
        count = count + 1
      }
    })
    return count
  }

  const calculateWorkOfficeCount = () => {
    let count = 0
    reportList.map(item => {
      if (item.is_work_home === false) {
        count = count + 1
      }
    })
    return count
  }

  const calculateNoReportCount = () => {
    let count = 0
    reportList.map(item => {
      if (item.is_work_home === null) {
        count = count + 1
      }
    })
    return count
  }

  return (
        <div>
          <div className="right-bar-item">
            <p className="right-bar-item-title" style={{ marginBottom: '0px' }}>Общее время отчетов</p>
            <div className="summary-report-time-wrapper">
              <span className="summary-report-time-date">{currentDay}</span>
              <span className="summary-report-time">{ reportList.length > 0 && (sumHoursWork() + ' часов') }</span>
            </div>
          </div>

          <div className="right-bar-item" style={{ paddingBottom: '20px' }}>
            <p className="right-bar-item-title" style={{ marginBottom: '8px' }}>Статистика за день</p>
            <div className="statistic-report-box">
                <div className={'statistic-report-box-item'}>
                <img alt="work from office" src={IconOffice}></img>
                <span>Из офиса</span>
                <span>{ reportList.length > 0 && calculateWorkOfficeCount() }</span>
                </div>
                <div className={'statistic-report-box-item'}>
                <img alt="work from home" src={IconHome}></img>
                <span>Из дома</span>
                <span>{ reportList.length > 0 && calculateWorkHomeCount() }</span>
                </div>
                <div className={'statistic-report-box-item'}>
                <img alt="no report" src={IconNoReport}></img>
                <span>Отчет не создан</span>
                <span>{ reportList.length > 0 && calculateNoReportCount() }</span>
                </div>
            </div>
          </div>
        </div>
  )
}

export default SummaryDayReportRightBar
