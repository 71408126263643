import '../../css/profile_delete_modal.css'
import CloseIcon from '../../icons/icon_close.svg'
import axios from 'axios'
import { GlobalStateContext } from '../GlobalStateContext.jsx'
import { useContext } from 'react'

const ProfileDeleteModal = (props) => {
  const { getCurrentBackendUrl } = useContext(GlobalStateContext)

  const onCloseModal = () => {
    props.closeModal(false)
  }

  const onDeleteProfile = async () => {
    await axios
      .delete(`${getCurrentBackendUrl()}/api/staff/delete/${props.modalInfo.id}/`)
      .then((response) => {
        props.closeModal(true)
      })
      // eslint-disable-next-line n/handle-callback-err
      .catch((error) => {
        // setApiError(error.response.data.message)
      })
  }

  return (
    <div className='profile-delete-modal-wrapper'>
      <div className='top-modal-bar-delete'>
        <p className='modal-title-delete'>Удаление пользователя</p>
        <img className='close-info-delete' onClick={onCloseModal} src={CloseIcon} />
      </div>
      <p className='profile-delete-modal-text'>
        {`Вы действительно хотите удалить сотрудника "${props.modalInfo.first} ${props.modalInfo.last}"? Это действие нельзя будет отменить.`}
      </p>
      <div className='save-changes-wrapper'>
          <button
            className={'button-save-changes'}
            onClick={onDeleteProfile}
          >
            Удалить
          </button>
        </div>
    </div>
  )
}

export default ProfileDeleteModal
