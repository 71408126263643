import { GlobalStateContext } from '../GlobalStateContext'
import { useContext } from 'react'
import calendarImage from '../../icons/calendar_icon.svg'

const Notifications = () => {
  const { getNotifications, synchronizeSchedule, changeSynchronizeSchedule, changeGetNotifications } = useContext(GlobalStateContext)

  const changeNotifications = () => {
    changeGetNotifications()
  }

  const changeSyncSchedule = () => {
    changeSynchronizeSchedule()
  }

  return (
        <div className="main-content-settings">
                <p style={{ marginTop: '24px', color: '#EDEDED', fontFamily: 'RobotoMedium', fontSize: '16px' }}>Уведомления о пропущенных отчетах</p>
                <div className="get-notifications" style={{ alignItems: 'center', margin: '24px 0px' }}>
                    <p style={{ margin: '0', color: '#8E8E8E', fontFamily: 'RobotoRegular', fontSize: '16px' }}>Получать уведомления</p>
                    <div className={getNotifications === 1 ? 'get-notifications-button' : 'get-notifications-button enabled'} onClick={changeNotifications} style={{ width: '14%' }}>
                        <div className={getNotifications === 1 ? 'get-notifications-button-slider' : 'get-notifications-button-slider enabled'}></div>
                    </div>
                </div>
                <div className="default-notifications">
                    <div className="radio-button-default-mode">
                        <div className="calendar-checkbox-container">
                            <input
                                type="radio"
                                checked={false}
                            />
                            <label style={{ left: '0px' }}> </label>
                        </div>
                        <p>По умолчанию</p>
                    </div>
                    <div className="input-default-mode">
                        <p>Понедельник – Пятница</p>
                        <input maxLength={5} style={{ width: '51px' }}></input>
                    </div>
                </div>
                <div className="line"></div>
                <div className="custom-notifications">
                    <div className="radio-button-default-mode">
                        <div className="calendar-checkbox-container">
                            <input
                                type="radio"
                                checked={false}
                            />
                            <label style={{ left: '0px' }}> </label>
                        </div>
                        <p>Настроить уведомления</p>
                    </div>
                    <div className="select-date-custom-notifications">
                        <p>На период:</p>
                        <div className="date-picker-custom-notifications">
                            <img src={calendarImage} />
                            <p>01.07.2024 - 05.07.2024</p>
                        </div>
                    </div>
                    <div className="header-custom-input">
                        <p>День недели</p>
                        <p>Уведомление</p>
                    </div>
                    <div className="day-of-week-checkboxes">
                        <div className="item-day-of-week-checkboxes">
                            <div className="checkbox-item-day-of-week">
                                <label className="bottom-panel-checkbox-text-input">
                                    <input type='checkbox'></input>
                                    <span className="checkmark"></span>
                                </label>
                                <p>Понедельник</p>
                            </div>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                        </div>
                        <div className="item-day-of-week-checkboxes">
                            <div className="checkbox-item-day-of-week">
                                <label className="bottom-panel-checkbox-text-input">
                                    <input type='checkbox'></input>
                                    <span className="checkmark"></span>
                                </label>
                                <p>Вторник</p>
                            </div>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                        </div>
                        <div className="item-day-of-week-checkboxes">
                            <div className="checkbox-item-day-of-week">
                                <label className="bottom-panel-checkbox-text-input">
                                    <input type='checkbox'></input>
                                    <span className="checkmark"></span>
                                </label>
                                <p>Среда</p>
                            </div>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                        </div>
                        <div className="item-day-of-week-checkboxes">
                            <div className="checkbox-item-day-of-week">
                                <label className="bottom-panel-checkbox-text-input">
                                    <input type='checkbox'></input>
                                    <span className="checkmark"></span>
                                </label>
                                <p>Четверг</p>
                            </div>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                        </div>
                        <div className="item-day-of-week-checkboxes">
                            <div className="checkbox-item-day-of-week">
                                <label className="bottom-panel-checkbox-text-input">
                                    <input type='checkbox'></input>
                                    <span className="checkmark"></span>
                                </label>
                                <p>Пятница</p>
                            </div>
                            <input maxLength={5} style={{ width: '51px' }}></input>
                        </div>
                    </div>
                    <div className="synchronize-schedule-text" style={{ alignItems: 'center', marginTop: '24px' }}>
                        <p style={{ margin: '0', color: '#8E8E8E', fontFamily: 'RobotoRegular', fontSize: '16px' }}>Синхронизировать с расписанием</p>
                        <div className={synchronizeSchedule === 1 ? 'get-notifications-button' : 'get-notifications-button enabled'} onClick={changeSyncSchedule} style={{ width: '14%' }}>
                            <div className={synchronizeSchedule === 1 ? 'get-notifications-button-slider' : 'get-notifications-button-slider enabled'}></div>
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default Notifications
