import '../../css/main.css'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import LastReportsColumn from './LastReportsColumn'
import MissedReportsColumn from './MissedReportsColumn'
import ProjectBox from '../ProjectBox'
import DefaultProjectIcon from '../../icons/default_project_icon.svg'
import { formatDateComma, getWorkDays } from '../Helpers/DateExtensions'
import { StaffPositionEnum, convertStaffPositionToString } from '../Helpers/StaffExtensions'

const StaffBox = ({ id, name, position, lastReports, projects, workHome, openReportModal }) => {
  const navigate = useNavigate()

  const handleOpenReportModal = (value) => {
    openReportModal({
      reportId: value.reportId,
      date: value.date,
      name
    })
  }

  const GoToSelectedStaff = () => {
    // changeDay(date);
    // navigate('/history', { state: { selectedReportId: id } });
    navigate('/detailed-staff-info', { state: { name, position: convertStaffPositionToString(position), id } })
  }

  return (
        <div className="staff-column-box">
            <div className="simple-box" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)' }}> {name} </div>
            <div className="simple-box" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)', gap: '10px' }}>
                {convertStaffPositionToString(position)}
            </div>
            <div className="report-dates" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)', paddingRight: '12px' }}>{
                <LastReportsColumn lastReports={lastReports} openReportModal={handleOpenReportModal} workDays={getWorkDays()} isDetailedStaffInfo={false} />
        }</div>
            <div className="project-box" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)', flexWrap: 'wrap', overflow: 'auto' }}>
                {projects.map((item, index) => (
                    <ProjectBox key={index} img={ DefaultProjectIcon } text={item} />
                ))}
            </div>
            <div className="report-dates" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)', paddingRight: '12px' }}>{
                <MissedReportsColumn reportsList={lastReports} />
        }</div>
            <div className="report-dates" style={{ borderRight: '1px solid rgba(85, 85, 85, 1)', paddingRight: '12px' }}>
                {
                lastReports.map((item, index) => (
                  (item.is_work_home === true && <p key={index} className='column-container-text'>{formatDateComma(item.date)}</p>)
                ))
                }
            </div>
            <div className="simple-box" style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '0px' }} onClick={GoToSelectedStaff}>
                <p style={{ overflowWrap: 'break-word', textAlign: 'center' }}> Перейти <br></br> к сотруднику </p>
                </div>
        </div>
  )
}

export default StaffBox
