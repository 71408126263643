/* eslint-disable no-unused-vars */
import SelectProject from '../SelectProject.jsx'
import React, { useState } from 'react'
import '../../css/block_report.css'
import '../../css/custom_text_viewer.css'
import { onCopyReport } from '../Helpers/ReportExtensions.js'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { SvgIcon } from '@mui/material'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import TextView from '../../components/TextView.jsx'

const BlockReport = ({ index, count, info, onRemove, projectsList, reportBlockChanged }) => {
  const [reportText, setReportText] = useState('')
  const [hoursWork, setHoursWork] = useState(0)
  const [minutesWork, setMinutesWork] = useState(0)

  const setupHoursWork = (e) => {
    setHoursWork(e.target.value)
    info.hours_work = e.target.value
    reportBlockChanged()
  }

  const setupMinutesWork = (e) => {
    if (e.target.value === '') {
      info.minutes_work = 0
      setMinutesWork(0)
    } else {
      setMinutesWork(e.target.value)
      info.minutes_work = e.target.value
    }
    reportBlockChanged()
  }

  const setupReportText = (text) => {
    setReportText(text)
    info.text_report = text
    reportBlockChanged()
  }

  // Функция для получения выбранного проекта
  const handleProjectChange = (project, id) => {
    info.project_id = id
    if (project === 'Выбрать проект') {
      onRemove(index)
    }
    reportBlockChanged()
  }

  const handleCopyReport = () => {
    onCopyReport([info], projectsList)
  }

  return (
    <div className='block-info'>
      <div className='pick-project'>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', zIndex: '1000' }}>
          <SelectProject
            projectId={info.project_id}
            onProjectChange={handleProjectChange}
            projectsList={projectsList}
          />
          <SvgIcon
            className='copy-report-block'
            alt='copy'
            component={ContentCopyIcon}
            style={{ marginLeft: '24px', width: '18px', height: '18px', padding: '4px' }}
            onClick={handleCopyReport}
          ></SvgIcon>
        </div>
        <div className='pick-time'>
          <span className='input-time'>
            <input
              maxLength={2}
              placeholder='Часы.'
              inputMode='numeric'
              type='text'
              pattern='[0-9]*'
              value={info.hours_work ? info.hours_work : ''}
              onChange={setupHoursWork}
            ></input>
          </span>
          <p>:</p>
          <span className='input-time'>
            <input
              maxLength={2}
              placeholder='Мин.'
              inputMode='numeric'
              type='text'
              pattern='[0-9]*'
              value={info.minutes_work ? info.minutes_work : ''}
              onChange={setupMinutesWork}
            ></input>
          </span>
        </div>
      </div>
      <TextView
      text={info.text_report ? info.text_report : reportText.value}
      setupNewText={setupReportText}
      readOnly={false}
      />
    </div>
  )
}

export default BlockReport
