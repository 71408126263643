import React, { useState } from 'react'
import '../../css/settings.css'
import ScheduleIcon from '../../icons/icon_schedule_settings.svg'
import NotificationsIcon from '../../icons/icon_notifications_settings.svg'
import Notifications from './Notifications'
import Schedule from './Schedule'

const Settings = () => {
  const [activeWindow, setActiveWindow] = useState(true)
  const openWindowSchedule = () => {
    setActiveWindow(false)
  }

  const openWindowNotifications = () => {
    setActiveWindow(true)
  }

  return (
        <div className="settings-main">
            <div className="toolbar-settings">
                <p>Настройки</p>
                <div className={`item-settings ${activeWindow ? '' : 'active'}`} onClick={openWindowSchedule}>
                    <img src={ScheduleIcon} />
                    <p>Расписание</p>
                </div>
                <div className={`item-settings ${activeWindow ? 'active' : ''}`} onClick={openWindowNotifications}>
                    <img src={NotificationsIcon}/>
                    <p>Уведомления</p>
                </div>
            </div>
            {activeWindow ? <Notifications /> : <Schedule />}
        </div>
  )
}

export default Settings
