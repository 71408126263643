/* eslint-disable array-callback-return */
export const onCopyReport = (reportsList, projectsList, concreteProjectName, concreteProjectNamesList) => {
  let result = ''
  reportsList.map((report, index) => {
    const reportHoursNotEmpty = report.hours_work !== '0' && report.hours_work !== 0 && report.hours_work
    const reportMinutesNotEmpty = report.minutes_work !== '0' && report.minutes_work !== 0 && report.minutes_work
    const tryGetProjectName =
      concreteProjectName ||
      (concreteProjectNamesList && concreteProjectNamesList[index]) ||
      tryGetProjectNameFromList(projectsList, report.project_id)

    const reportTime =
      (!reportHoursNotEmpty && !reportMinutesNotEmpty)
        ? '(Не указано затраченное время)'
        : `(${reportHoursNotEmpty ? (report.hours_work + ' ' + getHourWord(report.hours_work)) : ''}${
            reportMinutesNotEmpty ? (reportHoursNotEmpty ? ' ' : '') + (`${report.minutes_work} ` + getMinuteWord(report.minutes_work)) : ''
          })`

    const importOrders = report.text_report.replace(/<li>/g, '<br/>- ') // Заменяем <li> на <br/>-
    const addBrBetweenParagraphs = importOrders.replace(/<\/p><p>/g, '</p><br/><p>') // Добавляем <br/> между </p><p>
    const addBrBetweenUlAndParagraph = addBrBetweenParagraphs.replace(/<\/ul><p>/g, '</ul><br/><p>') // Добавляем <br/> между </ul><p>
    const addBrBetweenOlAndParagraph = addBrBetweenUlAndParagraph.replace(/<\/ol><p>/g, '</ol><br/><p>') // Добавляем <br/> между </ol><p>
    const removeHtmlTags = addBrBetweenOlAndParagraph.replace(/<(?!br\s*\/?)[^>]+>/g, '') // Удаляем все HTML-теги, кроме <br>
    const removeBrTags = removeHtmlTags.replace(/<br\s*\/?>/g, '\n') // Заменяем <br> или <br/> на \n
    const decodeHtmlEntities = removeBrTags
      .replace(/&#x27;/g, "'") // Заменяем &#x27; на апостроф
      .replace(/&lt;/g, '<') // Заменяем &lt; на <
      .replace(/&gt;/g, '>') // Заменяем &gt; на >
      .replace(/&amp;/g, '&') // Заменяем &amp; на &
      .replace(/&quot;/g, '"') // Заменяем &quot; на "
      .replace(/&apos;/g, "'") // Заменяем &apos; на апостроф

    const resultTextReport = decodeHtmlEntities

    const formattedText = `${tryGetProjectName || 'Проект не выбран'} ${reportTime}:\n${
      report.text_report ? resultTextReport : 'Описание проделанной работы по данному проекту отсутствует'
    }`

    result = result + formattedText + (index !== reportsList.length - 1 ? '\n\n' : '')
  })

  navigator.clipboard.writeText(result)
}

export const tryGetProjectNameFromList = (projectsList, id) => {
  const foundedProjectName = id === 0 ? 'Проект не был выбран' : projectsList.find((project) => project.id === id).name
  if (foundedProjectName) return foundedProjectName
  else return null
}

function getHourWord (input) {
  const hours = Number(input) // Преобразуем входное значение в число
  if (isNaN(hours)) {
    throw new Error('Invalid input: the value must be a number or a numeric string.')
  }

  const lastDigit = hours % 10
  const lastTwoDigits = hours % 100

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return 'часов' // Числа от 11 до 19 всегда используют "часов"
  }
  if (lastDigit === 1) {
    return 'час' // Числа, оканчивающиеся на 1 (кроме 11)
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return 'часа' // Числа, оканчивающиеся на 2, 3, 4 (кроме 12–14)
  }
  return 'часов' // Все остальные числа
}

function getMinuteWord (input) {
  const minutes = Number(input) // Преобразуем входное значение в число
  if (isNaN(minutes)) {
    throw new Error('Invalid input: the value must be a number or a numeric string.')
  }

  const lastDigit = minutes % 10
  const lastTwoDigits = minutes % 100

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return 'минут' // Числа от 11 до 19 всегда используют "минут"
  }
  if (lastDigit === 1) {
    return 'минута' // Числа, оканчивающиеся на 1 (кроме 11)
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return 'минуты' // Числа, оканчивающиеся на 2, 3, 4 (кроме 12–14)
  }
  return 'минут' // Все остальные числа
}

// Функция для поиска отсутствующих дат
export function findMissingReportDates (startDate, endDate, serverReports) {
// Преобразуем стартовую и конечную дату в объекты Date
  const start = new Date(startDate)
  const end = new Date(endDate)

  // Получаем список всех дат в указанном интервале
  const allDates = getDatesInRange(start, end)

  // Получаем список дат с отчётами
  const reportDates = serverReports.map(report =>
    new Date(report.date).setHours(0, 0, 0, 0) // Сравниваем только даты без времени
  )

  // Учитываем московское время
  const now = new Date()
  // const now = new Date('2025-01-24T19:00:00')
  const moscowOffset = 3 * 60 // Московское время UTC+3
  const currentTimeInMoscow = new Date(now.getTime() + now.getTimezoneOffset() * 60000 + moscowOffset * 60000)

  // Фильтруем сегодняшнюю дату, если время в Москве меньше 19:00
  const today = now.setHours(0, 0, 0, 0)
  const isAfterSomePM = currentTimeInMoscow.getHours() >= 19

  // Фильтруем даты, для которых нет отчётов
  const missingDates = allDates.filter(date => {
    const normalizedDate = date.setHours(0, 0, 0, 0)
    return !reportDates.includes(normalizedDate) &&
         (normalizedDate !== today || isAfterSomePM)
  })

  // Преобразуем даты в формат "DD.MM.YY" для вывода
  return missingDates
    .filter(date => date <= today)
    .map(date => date.toLocaleDateString('ru-RU'))
}

// Функция для получения всех дат в интервале без субботы и воскресенья
export function getDatesInRange (startDate, endDate) {
  const dates = []
  const currentDate = new Date(startDate)

  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate <= endDate) {
    const dayOfWeek = currentDate.getDay() // Получаем день недели (0 - воскресенье, 6 - суббота)
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      dates.push(new Date(currentDate)) // Копируем текущую дату, если это не суббота и не воскресенье
    }
    currentDate.setDate(currentDate.getDate() + 1) // Переходим на следующий день
  }

  return dates
}
