import '../../css/detailed-staff-info.css'
import { useEffect, useState } from 'react'
import DefaultProjectIcon from '../../icons/default_project_icon.svg'
import ProjectBox from '../ProjectBox'
import LastReportsColumn from './LastReportsColumn.jsx'
import { formatDateComma } from '../Helpers/DateExtensions.js'
import { getHourString } from '../Helpers/TimeExtensions.js'

const DetailedStaffInfoRightBar = (reportList, projectsList) => {
  const [ReportList, setReportList] = useState([])

  useEffect(() => {
    setReportList(reportList.reportList)
  }, [reportList])

  const createWeeklyReport = () => {

  }

  function getProjectsSet () {
    // Извлечение уникальных значений name_project
    const uniqueProjects = Array.from(new Set(
      ReportList.flatMap(item => item.item_reports.map(item => retrieveProjectName(item)))
    ))

    // console.log("StaffInfos: ", uniqueProjects.flat());
    return uniqueProjects.flat()
  }

  const retrieveProjectName = (item) => {
    const projectItem = reportList.projectsList ? reportList.projectsList.find(project => project.id === item.project_id) : null
    return projectItem ? projectItem.name : 'Проект не выбран'
  }

  function getPastWeekdays () {
    const today = new Date(reportList.endDate) // Текущая дата — воскресенье
    const pastWeekdays = []

    // День недели: 0 - воскресенье, 1 - понедельник, ..., 6 - суббота
    let dayOfWeek = today.getDay()

    // Если сегодня суббота (6) или воскресенье (0), устанавливаем последний рабочий день как пятница (5)
    if (dayOfWeek === 0) {
      // Воскресенье, поэтому отнимаем 2 дня, чтобы получить пятницу
      today.setDate(today.getDate() - 2)
      dayOfWeek = 5 // Пятница
    } else if (dayOfWeek === 6) {
      // Суббота, отнимаем 1 день, чтобы получить пятницу
      today.setDate(today.getDate() - 1)
      dayOfWeek = 5 // Пятница
    }

    // Перебираем рабочие дни от текущего дня до понедельника
    for (let i = dayOfWeek; i >= 1; i--) {
      const pastDate = new Date(today)
      pastDate.setDate(today.getDate() - (dayOfWeek - i))

      // Форматируем дату в 'YYYY-MM-DD'
      const formattedDate = pastDate.toISOString().split('T')[0]
      pastWeekdays.push(formattedDate)
    }

    return pastWeekdays
  }

  function sumHoursWork (index) {
    let resultHours = 0
    let resultMinutes = 0
    // eslint-disable-next-line array-callback-return
    reportList.reportList[index].item_reports.map(itemReport => {
      resultHours = resultHours + itemReport.hours_work
      resultMinutes = resultMinutes + itemReport.minutes_work
    })
    // console.log("reportList: ", reportList.reportList[index], resultHours, resultMinutes, Math.floor(resultMinutes / 60));

    return resultHours + Math.floor(resultMinutes / 60)
  }

  return (
        <div>
        <div className="generate-weekly-report" onClick={createWeeklyReport}>
            <p style={{ fontSize: '16px' }}>Сформировать недельный отчет</p>
          </div>

          <div className="right-bar-item">
            <p className="right-bar-item-title" style={{ marginBottom: '0px' }}>Проекты в отчетах</p>
            <div className="projects-box">
                {getProjectsSet().map((item, index) => (
                    <div key={index}>
                    <ProjectBox img={ DefaultProjectIcon } text={item} />
                    </div>
                ))}
            </div>
          </div>

          <div className="right-bar-item" style={{ paddingBottom: '2px' }}>
            <p className="right-bar-item-title" style={{ marginBottom: '-8px' }}>Общее время отчета</p>
            <div className="report-dates" style={{ paddingRight: '12px' }}>{
                <LastReportsColumn lastReports={reportList.reportList} openReportModal={() => {}} workDays={getPastWeekdays()} isDetailedStaffInfo={true} />
        }</div>
          </div>

          <div className="right-bar-item" style={{ paddingBottom: '2px' }}>
            <p className="right-bar-item-title" style={{ marginBottom: '-8px' }}>Работал из дома</p>
            <div className="report-dates" style={{ paddingRight: '12px' }}>
                {
                reportList.reportList.sort((a, b) => new Date(b.date) - new Date(a.date)).map((item, index) => (
                  (item.is_work_home === true &&
                    <div key={index} className="report-box-container">
                      <p className='column-container-text'>{formatDateComma(item.date)}</p>
                      <p> { getHourString(sumHoursWork(index)) } </p>
                      </div>
                  )
                ))
                }
            </div>
          </div>

          <div className="right-bar-item" style={{ paddingBottom: '2px' }}>
            <p className="right-bar-item-title" style={{ marginBottom: '-8px' }}>Расписание</p>
            <div className="report-dates" style={{ paddingRight: '12px' }}>
                {/* {
                reportList.reportList.map((item, index) => (
                   ( item.is_work_home === true &&
                    <div className="report-box-container">
                      <p className='column-container-text'>{formatDateComma(item.date)}</p>
                      <p> { sumHoursWork(index) + " часов" } </p>
                      </div>
                     )
                ))
                } //Добавить сюда подгрузку расписания сотрудника на выбранную неделю */}
            </div>
          </div>
        </div>
  )
}

export default DetailedStaffInfoRightBar
