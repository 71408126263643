import { useEffect, useState } from 'react'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertToHTML } from 'draft-convert'

const TextView = (props) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

  useEffect(() => {
    if (!props.text) return
    const newEditorState = convertToEditorState(props.text)
    setEditorState(newEditorState)
  }, [props.readOnly])

  // Регулярное выражение для поиска URL
  const urlRegex = /(https?:\/\/[^\s<>]+)/g

  const highlightLinks = (input) => {
    if (!input) return '' // Если входной текст пустой, возвращаем пустую строку
    return input.replace(urlRegex, (match) => {
      return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`
    })
  }

  const convertToEditorState = (html) => {
    const replacedHtml = highlightLinks(html)
    const blocksFromHTML = convertFromHTML(replacedHtml)
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
    return EditorState.createWithContent(contentState)
  }

  useEffect(() => {
    const html = convertToHTML(editorState.getCurrentContent())
    if (props.readOnly === undefined || props.readOnly === true) {
      return
    }
    props.setupNewText(html)
  }, [editorState])

  return (
    <Editor
      toolbarClassName='editorToolbar'
      wrapperClassName={'editorWrapper ' + (props.readOnly === false ? 'edit' : 'read')}
      editorClassName='editor'
      editorState={editorState}
      readOnly={props.readOnly}
      toolbarHidden={props.readOnly}
      onEditorStateChange={setEditorState}
      toolbar={{
        options: ['inline', 'list'],
        inline: {
          inDropdown: false,
          className: 'editorButton',
          options: ['bold', 'italic', 'underline', 'strikethrough']
        },
        list: {
          inDropdown: false,
          className: 'editorButton',
          options: ['unordered', 'ordered']
        },
        link: {
          inDropdown: false,
          className: 'editorButton',
          defaultTargetOption: '_blank',
          showOpenOptionOnHover: false,
          options: ['link', 'unlink']
        }
      }}
    />
  )
}

export default TextView
