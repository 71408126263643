// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
    color: #F3ECEC;
    font-family: "RobotoRegular";
    line-height: 24px;
    font-size: 16px;
    width: 100%; 
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    display: block;
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/css/clickable_links_text.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,4BAA4B;IAC5B,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".text {\n    color: #F3ECEC;\n    font-family: \"RobotoRegular\";\n    line-height: 24px;\n    font-size: 16px;\n    width: 100%; \n    word-break: break-word;\n    overflow-wrap: break-word;\n    white-space: normal;\n    display: block;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
