/* eslint-disable no-unneeded-ternary */
import React, { createContext, useState } from 'react'

const GlobalStateContext = createContext()

const GlobalStateProvider = ({ children }) => {
  const [defaultDayReport, setDefaultDayReport] = useState({ date: new Date(), report_id: 0 })
  const [enableDarkMode, setEnableDarkMode] = useState(localStorage.getItem('darkMode'))
  const [getNotifications, setGetNotifcations] = useState(localStorage.getItem('getNotifcations'))
  const [synchronizeSchedule, setSyncSchedule] = useState(localStorage.getItem('synchronizeSchedule'))

  const [onSickLeave, setOnSickLeave] = useState((typeof localStorage.getItem('onSickLeave') === 'boolean') ? localStorage.getItem('onSickLeave') : (localStorage.getItem('onSickLeave') === 'true' ? true : false))
  const [onVacation, setOnVacation] = useState((typeof localStorage.getItem('onVacation') === 'boolean') ? localStorage.getItem('onVacation') : (localStorage.getItem('onVacation') === 'true' ? true : false))

  const [ProfileInfo, setProfileInfo] = useState({
    id: localStorage.getItem('id'),
    firstname: localStorage.getItem('firstname'),
    middlename: localStorage.getItem('middlename'),
    lastname: localStorage.getItem('lastname'),
    email: localStorage.getItem('email'),
    avatar: localStorage.getItem('avatar'),
    role: localStorage.getItem('role'),
    isOnSickLeave: localStorage.getItem('onSickLeave'),
    isOnVacation: localStorage.getItem('onVacation')
  })

  const getCurrentBackendUrl = () => {
    return process.env.REACT_APP_BACKEND_URL
  }

  const getProfileInfo = () => {
    return ProfileInfo
  }

  const updateProfileInfo = (info) => {
    setProfileInfo(prevState => ({
      ...prevState,
      ...info
    }))
    localStorage.setItem('firstname', info.firstname)
    localStorage.setItem('middlename', info.middlename)
    localStorage.setItem('lastname', info.lastname)
    localStorage.setItem('email', info.email)
    localStorage.setItem('avatar', getCurrentBackendUrl() + '' + info.avatar.match(/\/media\/.*$/)[0])
    localStorage.setItem('role', info.role)
    localStorage.setItem('OnSickLeave', info.isOnSickLeave)
    localStorage.setItem('OnVacation', info.isOnVacation)
  }

  const clearProfileInfo = () => {
    setProfileInfo({ id: '', firstname: '', middlename: '', lastname: '', email: '', avatar: '', role: '' })
    localStorage.removeItem('id')
    localStorage.removeItem('firstname')
    localStorage.removeItem('middlename')
    localStorage.removeItem('lastname')
    localStorage.removeItem('email')
    localStorage.removeItem('avatar')
    localStorage.removeItem('role')
    localStorage.removeItem('OnSickLeave')
    localStorage.removeItem('OnVacation')
  }

  const changeDay = (day, id) => {
    setDefaultDayReport({ date: day, report_id: id })
  }

  const changeTheme = () => {
    if (enableDarkMode === 2) {
      setEnableDarkMode(1)
      localStorage.setItem('darkMode', 1)
    } else {
      setEnableDarkMode(2)
      localStorage.setItem('darkMode', 2)
    }
  }

  const changeGetNotifications = () => {
    if (getNotifications === 2) {
      setGetNotifcations(1)
      localStorage.setItem('getNotifcations', 1)
    } else {
      setGetNotifcations(2)
      localStorage.setItem('getNotifications', 2)
    }
  }

  const changeSynchronizeSchedule = () => {
    if (synchronizeSchedule === 2) {
      setSyncSchedule(1)
      localStorage.setItem('synchronizeSchedule', 1)
    } else {
      setSyncSchedule(2)
      localStorage.setItem('synchronizeSchedule', 2)
    }
  }

  const changeOnSickLeave = (status) => {
    localStorage.setItem('onSickLeave', status)
    setOnSickLeave(status)
  }

  const changeOnVacation = (status) => {
    localStorage.setItem('onVacation', status)
    setOnVacation(status)
  }

  const getToken = () => {
    return localStorage.getItem('token')
  }

  const setToken = (token) => {
    localStorage.setItem('token', token)
  }

  const removeToken = () => {
    localStorage.removeItem('token')
  }

  const getRefreshToken = () => {
    return localStorage.getItem('refresh_token')
  }

  const setRefreshToken = (refreshToken) => {
    localStorage.setItem('refresh_token', refreshToken)
  }

  const removeRefreshToken = () => {
    localStorage.removeItem('refresh_token')
  }

  const setRoleAndId = (role, id) => {
    setProfileInfo({ ...ProfileInfo, role, id })
    localStorage.setItem('id', id)
    localStorage.setItem('role', role)
  }

  const logout = () => {
    removeToken()
    removeRefreshToken()
    clearProfileInfo()
  }

  return (
    <GlobalStateContext.Provider value={{
      defaultDayReport,
      enableDarkMode,
      getNotifications,
      synchronizeSchedule,
      onSickLeave,
      onVacation,
      changeOnSickLeave,
      changeOnVacation,
      changeDay,
      logout,
      changeTheme,
      changeGetNotifications,
      changeSynchronizeSchedule,
      getCurrentBackendUrl,
      getToken,
      setToken,
      removeToken,
      setRefreshToken,
      getRefreshToken,
      removeRefreshToken,
      getProfileInfo,
      updateProfileInfo,
      clearProfileInfo,
      setRoleAndId
    }}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export { GlobalStateContext, GlobalStateProvider }
