import '../../css/main.css'
import editPencilIcon from '../../icons/edit_pencil.svg'
import deleteBoxIcon from '../../icons/delete_box.svg'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ProjectBox from '../ProjectBox'
import { convertStaffPositionToString, StaffPositionEnum } from '../Helpers/StaffExtensions'
import DefaultProjectIcon from '../../icons/default_project_icon.svg'

const MainWeekBox = ({ id, first, last, role, email, slackId, date, projects, assignments, openEditProfileModal, openDeleteProfileModal }) => {
  const navigate = useNavigate()

  const onDeleteStaff = () => {
    // navigate(`/write-report?date=${format(date, 'yyyy-MM-dd')}`)
  }

  const onOpenEditProfileModal = () => {
    openEditProfileModal(id, first, last, role, email, slackId, date, projects, assignments)
  }

  const onOpenDeleteProfileModal = () => {
    openDeleteProfileModal(id, first, last)
  }

  const getAssigneesBlock = () => {
    if (!assignments || assignments.length === 0) {
      return 'Нет назначений'
    }

    if (role === StaffPositionEnum.MANAGER) {
      const developers = assignments.map((dev) => `${dev.firstname} ${dev.lastname}`).join('; ')
      return `Разработчики: ${developers}`
    } else {
      const manager = assignments[0]
      return `Менеджер: ${manager.firstname} ${manager.lastname}`
    }
  }

  return (
    <div className='admin-column-box' style={{ padding: '8px 0' }}>
      <div className='row-box' style={{ borderRight: '1px solid rgba(85, 85, 85, 1)' }}>
        {first + ' ' + last}
      </div>
      <div className='row-box' style={{ borderRight: '1px solid rgba(85, 85, 85, 1)' }}>
        {convertStaffPositionToString(role)}
      </div>
      <div
        className='row-box'
        style={{
          borderRight: '1px solid rgba(85, 85, 85, 1)',
          gap: '10px',
          paddingRight: '16px',
          justifyContent: projects && projects.length > 0 ? 'flex-start' : 'flex-end',
          columnGap: '4px',
          rowGap: '6px',
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        {projects.length > 0
          ? projects.map((item, index) => <ProjectBox key={index} img={DefaultProjectIcon} text={item.name} />)
          : 'Проект не назначен'}
      </div>
      <div
        className='row-box'
        style={{
          borderRight: '1px solid rgba(85, 85, 85, 1)',
          paddingRight: '16px',
          justifyContent: assignments && assignments.length > 0 ? 'flex-start' : 'flex-end'
        }}
      >
        {getAssigneesBlock()}
      </div>
      <div className='row-box' style={{ justifyContent: 'center' }}>
        <img src={editPencilIcon} onClick={onOpenEditProfileModal} />
        <img src={deleteBoxIcon} style={{ marginLeft: '12px' }} onClick={onOpenDeleteProfileModal} />
      </div>
    </div>
  )
}

export default MainWeekBox
