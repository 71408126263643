import '../../css/main.css'
import React, { useEffect, useState } from 'react'
import LastReportsBox from './LastReportsBox'
import { fillMissingReports } from '../Helpers/DateExtensions'

const LastReportsColumn = ({ lastReports, openReportModal, workDays, isDetailedStaffInfo }) => {
  const [AllDaysReports, setAllDaysReports] = useState([])

  useEffect(() => {
    const { completeReports } = fillMissingReports(lastReports, workDays)
    setAllDaysReports(completeReports)

    // console.log("Completed and Missed lists: ", completeReports, missingReportDates);
  }, [lastReports])

  return (
    AllDaysReports.map((item, index) => (
            <LastReportsBox key={index} date={item.date} reportsList={item.item_reports} id={item.id} openModal={openReportModal} isDetailedStaffInfo={isDetailedStaffInfo} />
    ))
  )
}

export default LastReportsColumn
