// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cont {
    display: flex;
}

.select-project-container {
    position: relative;
    z-index: 1;
    display: flex;
    gap: 10px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0);
}

.select-project-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: default;
}

.select-project-selector img {
    width: 16px;
    height: 8px;
}

.projects-array {
    position: absolute;
    z-index: 200;
    display: flex;
    width: 108%;
    max-height: 90px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 30%;
    margin-left: -12px;
    flex-direction: column;
    background-color: #313133;
    border-radius: 4px;
    padding: 8px;
    gap: 8px;
}

.projects-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    padding: 4px;
    cursor: default;
    border-radius: 4px;
    margin: 0px 6px;
    width: 100px;
}

.projects-item p {
    width: 100%;
}


.projects-item:hover {
    background-color: rgba(137, 137, 137, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/css/select_project.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,QAAQ;IACR,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,QAAQ;IACR,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;;AAGA;IACI,0CAA0C;AAC9C","sourcesContent":[".cont {\n    display: flex;\n}\n\n.select-project-container {\n    position: relative;\n    z-index: 1;\n    display: flex;\n    gap: 10px;\n    flex-direction: column;\n    background-color: rgba(255, 255, 255, 0);\n}\n\n.select-project-selector {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 8px;\n    cursor: default;\n}\n\n.select-project-selector img {\n    width: 16px;\n    height: 8px;\n}\n\n.projects-array {\n    position: absolute;\n    z-index: 200;\n    display: flex;\n    width: 108%;\n    max-height: 90px;\n    overflow-y: scroll;\n    overflow-x: hidden;\n    margin-top: 30%;\n    margin-left: -12px;\n    flex-direction: column;\n    background-color: #313133;\n    border-radius: 4px;\n    padding: 8px;\n    gap: 8px;\n}\n\n.projects-item {\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    gap: 4px;\n    padding: 4px;\n    cursor: default;\n    border-radius: 4px;\n    margin: 0px 6px;\n    width: 100px;\n}\n\n.projects-item p {\n    width: 100%;\n}\n\n\n.projects-item:hover {\n    background-color: rgba(137, 137, 137, 0.2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
