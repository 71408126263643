import '../../css/main.css'
import React, { useEffect, useState } from 'react'
import { formatDateComma, getWorkDays } from '../Helpers/DateExtensions'

const MissedReportsColumn = (lastReports) => {
  const [MissedDayReports, setMissedDayReports] = useState([])

  useEffect(() => {
    const missingReportDates = fillMissingReports(lastReports.reportsList)
    setMissedDayReports(missingReportDates)
  }, [lastReports])

  function fillMissingReports (reports) {
    // Шаг 1: Получаем рабочие дни недели
    const workDays = getWorkDays() // Эта функция возвращает рабочие дни недели

    // Шаг 2: Создаём объект для быстрого поиска по дате
    const reportsByDate = {}
    reports.forEach(report => {
      reportsByDate[report.date] = report
    })

    // Шаг 3: Создаём массив для хранения дат с отсутствующими отчётами
    const missingReportDates = []

    // Шаг 4: Создаём итоговый массив с рабочими днями
    // eslint-disable-next-line array-callback-return, no-unused-vars
    const completeReports = workDays.map(day => {
      if (reportsByDate[day]) {
        // Если отчёт на эту дату уже существует, добавляем его в итоговый массив
      } else {
        // Если отчёта нет, создаём пустой отчёт с указанием только даты
        missingReportDates.push(day) // Добавляем дату, для которой нет отчёта
      }
    })

    // Возвращаем и итоговый массив с отчётами, и список дат с отсутствующими отчётами
    return missingReportDates
  }

  return (
    MissedDayReports.map((item, index) => (
            // <LastReportsBox date={item.date} reportsList={item.item_reports} id={item.id} />
            <p key={index} className='missed-report-staff'> {formatDateComma(item)}</p>
    ))
  )
}

export default MissedReportsColumn
