export function getHourString (number) {
  const lastDigit = number % 10
  const lastTwoDigits = number % 100

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return `${number} часов`
  }

  if (lastDigit === 1) {
    return `${number} час`
  }

  if (lastDigit >= 2 && lastDigit <= 4) {
    return `${number} часа`
  }

  return `${number} часов`
}

export function getMinuteString (n) {
  const declensions = ['минута', 'минуты', 'минут']
  const m = Math.abs(n) % 100
  const n1 = m % 10
  if (m > 10 && m < 20) { return `${n} ${declensions[2]}` }
  if (n1 > 1 && n1 < 5) { return `${n} ${declensions[1]}` }
  if (n1 === 1) { return `${n} ${declensions[0]}` }
  return `${n} ${declensions[2]}`
}

export function getPreviousDateIfTimeInRange (date) {
  // Преобразуем входную дату в московское время
  const mskTime = new Date(date).toLocaleString('en-US', { timeZone: 'Europe/Moscow' })

  // Создаем объект Date для московского времени
  const dateMsk = new Date(mskTime)

  // Получаем часы и минуты
  const hours = dateMsk.getHours()
  const minutes = dateMsk.getMinutes()

  // Проверяем, что время находится в интервале от 00:00 до 11:58
  if (
    (hours === 0 && minutes >= 0) || // с 00:00
    (hours > 0 && hours < 11) || // с 01:00 до 10:59
    (hours === 11 && minutes <= 58) // до 11:58
  ) {
    // Если время в интервале, возвращаем предыдущую дату с тем же временем
    dateMsk.setDate(dateMsk.getDate() - 1) // Уменьшаем на 1 день
    return dateMsk
  }

  // Если время не в интервале, возвращаем исходную дату
  return date
}
