/* eslint-disable n/handle-callback-err */
import React, { useState, useRef, useEffect, useContext } from 'react'
import { getYear } from 'date-fns'
import Modal from 'react-modal'
import axios from 'axios'
import '../../css/history_reports.css'
import '../../index.css'
import Toolbar from '../Toolbar'
import Profile from '../Profile'
import Block from './BlockReport'
import calendarImage from '../../icons/calendar_icon.svg'
import leftButtonImage from '../../icons/icon_left.svg'
import rightButtonImage from '../../icons/icon_right.svg'
import profileButtonImage from '../../icons/profile_img_icon.svg'
import AddReportImage from '../../icons/add_report_button.svg'
import SendMessageIcon from '../../icons/send_message_icon.svg'
import SendMessageWindow from '../SendMessageWindow'
import MissedReportsIcon from '../../icons/missed_reports_icon.svg'
import Calendar from '../../jsx/DaysCalendar/ExternalDayListenerCalendar.jsx'
import { GlobalStateContext } from '../GlobalStateContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { sortProjectsByRole } from '../Helpers/ProjectsExtensions'
import { onCopyReport } from '../Helpers/ReportExtensions'

function HistoryReportsPage () {
  const monthNames = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря']

  const [ReportItems, setReportItems] = useState([{ project_id: 0, hours_work: 0, minutes_work: 0, text_report: '' }])
  const [IsWorkHome, setIsWorkHome] = useState(false)
  const [isAllowedToSaveReport, setIsAllowedToSaveReport] = useState(false)
  const navigate = useNavigate()
  const { getProfileInfo, getCurrentBackendUrl } = useContext(GlobalStateContext)

  const [ResponseReport, setResponseReport] = useState({
    creator_id: getProfileInfo().id,
    date: '',
    item_reports: ReportItems,
    is_work_home: false,
    work_home_reason: ''
  })

  const [ProjectsList, setProjectsList] = useState([])

  // Получаем объект location с текущим URL
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const isValidDateFormat = (dateString) => {
    const regex = /^(20[0-4][0-9]|2050)-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
    return regex.test(dateString)
  }

  const initialDate =
  params.get('date') && isValidDateFormat(params.get('date')) ? new Date(params.get('date')) : new Date()
  const [selectedDate, setSelectedDate] = useState(initialDate)

  // Используем useEffect для обновления ResponseReport при изменении ReportItems
  useEffect(() => {
    setResponseReport(prevState => ({
      ...prevState,
      item_reports: ReportItems // Обновляем item_reports
    }))
  }, [ReportItems])

  const [profile, setVisibleProfile] = useState(false)
  const [calendar, setVisibleCalendar] = useState(false)
  const [reportDay, setReportDay] = useState()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const textRef = useRef(null)
  const profileRef = useRef(null)
  const profileRef1 = useRef(null)
  const calendarRef = useRef(null)
  const calendarRef1 = useRef(null)
  const childRef = useRef(null)
  const [availablePlusDays, setAvailablePlusDays] = useState(true)
  const [loading, setLoading] = useState(true)

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const changeVisibleProfile = () => {
    setVisibleProfile(!profile)
  }

  const changeVisibleCalendar = () => {
    setVisibleCalendar(!calendar)
  }

  const addNewReportItem = () => {
    setReportItems((prevItems) => [...prevItems, { project_id: 0, hours_work: 0, minutes_work: 0, text_report: '' }])
    setIsAllowedToSaveReport(false)
  }

  const removeReportItem = (index) => {
    if ((index !== 0 && ReportItems.length !== 1) || ReportItems.length > 1) {
      setReportItems((prevItems) => prevItems.filter((_, i) => i !== index))
    }
  }

  const checkAllowedToSaveReport = (report) => {
    if (!report || !Array.isArray(report)) {
      setIsAllowedToSaveReport(false)
      return
    }

    const isValid = report.every(item => {
      // Проверяем, что значения часов и минут содержат только цифры
      const isHoursValid = /^[0-9]+$/.test(item.hours_work) // Только числа
      const isMinutesValid = /^[0-9]+$/.test(item.minutes_work) // Только числа

      // Преобразуем строковые значения в числа и проверяем их
      const projectId = parseInt(item.project_id, 10) // Преобразование в число
      const hoursWork = (item.hours_work === '' ? 0 : (isHoursValid ? parseInt(item.hours_work, 10) : NaN)) // Преобразование в число
      const minutesWork = (item.minutes_work === '' ? 0 : (isMinutesValid ? parseInt(item.minutes_work, 10) : NaN)) // Преобразование в число

      // console.log('received report item: ', projectId, hoursWork, minutesWork, item.text_report)
      // // Логирование всех проверок
      // console.log('check report item: ',
      //   !isNaN(projectId), projectId !== 0,
      //   !isNaN(hoursWork), (hoursWork > 0 || (hoursWork === 0 && minutesWork > 0)), hoursWork < 24,
      //   !isNaN(minutesWork), minutesWork >= 0 && minutesWork <= 59,
      //   item.text_report !== '<p></p>'
      // )

      return (
        // Проверяем, что project_id — число и не 0
        !isNaN(projectId) && projectId !== 0 &&

        // Проверяем, что hours_work — число: > 0 или часы = 0 и минуты > 0, при этом не больше чем 23
        !isNaN(hoursWork) && (hoursWork > 0 || (hoursWork === 0 && minutesWork > 0)) && hoursWork < 24 &&

        // Проверяем minutes_work: валидное число и в диапазоне [0, 59]
        !isNaN(minutesWork) && minutesWork >= 0 && minutesWork <= 59 &&

        // Текст блока отчета не пустой
        item.text_report !== '<p></p>'
      )
    })

    // console.log('isAllowedToSaveReport: ', isValid)
    // console.log('')

    setIsAllowedToSaveReport(isValid)
  }

  const getAllProjects = async () => {
    await axios.get(`${getCurrentBackendUrl()}/api/projects`)
      .then((response) => {
        // console.log("getProjects: ", response.data);
        setProjectsList(sortProjectsByRole(Number(getProfileInfo().id), Number(getProfileInfo().role), response.data))
      })
      .catch((error) => {
        // console.log('Error:', error);
      })
  }

  const updateDateInURL = (newDate) => {
    const params = new URLSearchParams(location.search)
    params.set('date', newDate)
    setReportItems([{ project_id: 0, hours_work: 0, minutes_work: 0, text_report: '' }])
    getReportByDate(newDate)
    navigate({
      pathname: location.pathname,
      search: params.toString()
    })
  }

  useEffect(() => {
    const adjustFontSize = () => {
      const element = textRef.current
      const initialFontSize = 18
      let currentFontSize = initialFontSize

      element.style.fontSize = `${initialFontSize}px`
      element.style.overflow = 'hidden'
      while (element.clientHeight > 28) {
        currentFontSize -= 1
        element.style.fontSize = `${currentFontSize}px`
      }

      element.style.whiteSpace = 'normal'
    }

    adjustFontSize()
    window.addEventListener('resize', adjustFontSize)

    return () => {
      window.removeEventListener('resize', adjustFontSize)
    }
  }, [reportDay])

  useEffect(() => {
    getAllProjects()
  }, [])

  useEffect(() => {
    checkAllowedToSaveReport(ReportItems)
  }, [ReportItems])

  const onReportBlockChanged = () => {
    // console.log('ReportItems: ', ReportItems)
    setIsAllowedToSaveReport(false)
    checkAllowedToSaveReport(ReportItems)
  }

  useEffect(() => {
    if (ResponseReport.is_work_home === true) {
      ResponseReport.work_home_reason = ''
    }
    // console.log("ResponseReport.work_home = " + ResponseReport.is_work_home + " " + ResponseReport.work_home_reason);
  }, [ResponseReport.is_work_home])

  const customStyles = {
    content: {
      display: 'inline-block',
      width: '28vw',
      padding: '32px',
      border: 'none',
      outline: 'none',
      borderRadius: '12px',
      backgroundColor: '#313133',
      overflow: 'hidden'
    },
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(18, 22, 25, 0.75)',
      margin: 'auto',
      zIndex: 1000
    }
  }

  const getReportByDate = async (date) => {
    try {
      setLoading(true)
      const token = localStorage.getItem('token')
      const id = getProfileInfo().id
      const response = await axios.get(`${getCurrentBackendUrl()}/api/reports?creator_id=${id}&end_date=${date}&start_date=${date}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setReportItems(response.data[0].item_reports)
      setResponseReport(response.data[0])
      setLoading(false)
      // console.log(ReportItems[0])
      // console.log(ResponseReport.item_reports.length, loading)
    } catch (error) {
      setLoading(false)
      // console.log(error)
    }
  }

  const getFormattedDate = (date) => {
    setResponseReport(prevState => ({
      ...prevState, // Копируем все существующие значения из ResponseReport
      date // Обновляем только поле date
    }))
    updateDateInURL(date)
    const receivedDate = new Date(date)
    const today = new Date()
    setAvailablePlusDays(receivedDate.setHours(3, 0, 0, 0) < today.setHours(3, 0, 0, 0))
  }

  const changeDate = (day) => {
    setSelectedDate(new Date(day))
    updateDateInURL(new Date(day))
  }

  const changeNameDay = (nameDay) => {
    setReportDay(nameDay)
  }

  const MinusDay = () => {
    childRef.current.changeDayThroughArrow('-')
  }

  const PlusDay = () => {
    childRef.current.changeDayThroughArrow('+')
  }

  const handleClickOutside = (event) => {
    if (profileRef.current && !profileRef.current.contains(event.target) && !profileRef1.current.contains(event.target)) {
      setVisibleProfile(false)
    }

    if (calendarRef.current && !calendarRef.current.contains(event.target) && !calendarRef1.current.contains(event.target)) {
      setVisibleCalendar(false)
    }
  }

  const setupWorkHomeReason = (e) => {
    setResponseReport(prevState => ({
      ...prevState, // Копируем все существующие значения из ResponseReport
      work_home_reason: e.target.value // Обновляем только поле work_home
    }))
  }

  const setupIsWorkHome = (e) => {
    setIsWorkHome(e.target.checked)
    setResponseReport(prevState => ({
      ...prevState,
      is_work_home: e.target.checked
    // work_home_reason: IsWorkHome ? "Работал из офиса" : '',        // Обновляем только поле work_home
    }))
  }

  const sendReport = async () => {
    await axios.post(`${getCurrentBackendUrl()}/api/reports/`, ResponseReport)
      .then((response) => {
      // console.log('Success:', response.data);
        navigate('/')
      })
      .catch((error) => {
      // console.log('Error:', error);
      })
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    Modal.setAppElement(document.getElementById('root'))

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleCopyAllReportInfo = () => {
    onCopyReport(ResponseReport.item_reports, ProjectsList)
  }

  return (
    <div className='main-wrapper' onClick={handleClickOutside}>
      <Modal className="modal-window" isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <SendMessageWindow closeModal={closeModal} currentDate={ResponseReport.date} />
      </Modal>
      <Toolbar page={'write_report'} />
      <div className="content-report">
        <div className="header-report">
          <div className="header-report-item" style={{ gap: '8px', backgroundColor: '#007EC5', cursor: 'default' }} onClick={openModal}>
            <img src={SendMessageIcon} alt="Send Message" />
            <p>Написать сообщение</p>
          </div>
          <div className="header-report-item">
            <p>{`${new Date().getDate()} ${monthNames[new Date().getMonth()]} ${getYear(new Date())}`}</p>
          </div>
          <div className="header-report-item" style={{ padding: '0px 10px', justifyContent: 'space-between' }}>
            <img src={leftButtonImage} alt="Previous Day" onClick={MinusDay} />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px', cursor: 'default' }} onClick={changeVisibleCalendar} ref={calendarRef1}>
              <img src={calendarImage} alt="Calendar" />
              <p style={{ margin: '0' }} ref={textRef}>{reportDay}</p>
            </div>
            { availablePlusDays && <img src={rightButtonImage} alt="Next Day" onClick={PlusDay} />}
          </div>
          <div className="header-report-item" style={{ background: 'transparent' }}>
            <span className="profile-box">
              <img src={ getProfileInfo().avatar ? (getProfileInfo().avatar) : profileButtonImage } alt="Profile" onClick={changeVisibleProfile} ref={profileRef1} />
            </span>
          </div>
        </div>
        <div className="main-report">
          <div className={profile === false ? 'profile-invisible' : 'profile-visible'} ref={profileRef}>
            <Profile closeProfile={changeVisibleProfile} />
          </div>
          <div className={calendar === false ? 'calendar-invisible' : 'calendar-visible'} ref={calendarRef}>
            <Calendar
              changeDayInParent={changeDate}
              formattedDate={getFormattedDate}
              ref={childRef}
              selectedDate={selectedDate}
              changeNameDay={changeNameDay}
            />
          </div>
          {
            (loading === false && (ResponseReport.item_reports[0].project_id === 0 || ResponseReport.item_reports[0].hours_work === 0)) &&
            <div className="notification-missed-reports">
            <img src={MissedReportsIcon} alt="Missed Reports" />
            <p>{`Внимание! Отчет за ${initialDate.toLocaleDateString('ru-RU')} не был найден в системе`} </p>
          </div>
          }
          <div className="main-content-write-report">
            <div className="large-box-reports">
            { loading === true
              ? <div style={{ marginTop: '30%' }} className={'circular-progress-bar'}></div>
              : <div className="box-reports">
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <p className="h1-box-reports">Отчёт</p>
                  <span className="bottom-panel-line"></span>
                </div>
                <div className="blocks">

                  {ReportItems.map((item, index) => (
                    <Block key={index} index={index} info={item} count={ReportItems.length} onRemove={removeReportItem} projectsList={ProjectsList} reportBlockChanged={onReportBlockChanged}/>
                  ))}

                </div>
                <div className="bottom-panel-reports">
                  <span className="button-add-report" onClick={addNewReportItem} style={{ cursor: 'default' }}>
                    <img src={AddReportImage} alt="Add Report" />
                    <p>Добавить проект</p>
                  </span>
                  <span className="bottom-panel-line"></span>
                  <span className="bottom-panel-checkbox">
                    <div className="bottom-panel-checkbox-text">
                      <label className="bottom-panel-checkbox-text-input">
                        <input type='checkbox' onChange={setupIsWorkHome} checked={ResponseReport.is_work_home} value={ResponseReport.is_work_home}></input>
                        <span className="checkmark"></span>
                      </label>
                      <p>Работал из дома</p>
                    </div>
                    <input
                      className="bottom-panel-checkbox-input"
                      type='text'
                      placeholder=' Причина работы из дома'
                      disabled={!ResponseReport.is_work_home}
                      value={ResponseReport.is_work_home ? ResponseReport.work_home_reason : ''}
                      style={{ caretColor: '#007EC4' }}
                      onChange={setupWorkHomeReason}
                    >
                    </input>
                  </span>
                  <div className='copy-report-block' style={{ marginTop: '20px', marginBottom: '-48px' }} onClick={handleCopyAllReportInfo}>Скопировать весь отчет</div>
                  <button disabled={!isAllowedToSaveReport} onClick={sendReport} className={`button-send-reports${isAllowedToSaveReport ? '' : ' disabled'}`}>
                    Сохранить отчёт
                  </button>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HistoryReportsPage
