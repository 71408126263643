import DeleteSelectItem from '../../icons/icon_delete_select_item.svg'
import ArrowSelectIcon from '../../icons/icon_select_arrow.svg'
import DefaultProjectIcon from '../../icons/default_project_icon.svg'
import React, { useState, useEffect } from 'react'
import '../../css/multiselect.css'
import { StaffPositionEnum } from '../Helpers/StaffExtensions'

const AssignProjects = ({ role, userId, staff, projects, selectedProjects }) => {
  const [SelectedItems, setSelectedItems] = useState([])
  const [VisibleArray, setVisibleArray] = useState(false)

  const changeVisibleArray = () => {
    setVisibleArray(!VisibleArray)
  }

  const AddItemInArray = (item) => {
    if (!SelectedItems.includes(item)) {
      setSelectedItems((prevItems) => [...prevItems, item])
      setVisibleArray(false)
    } else {
      if (checkIsSelected(item)) {
        DeleteItemInArray(item)
        setVisibleArray(false)
      }
    }
  }

  const DeleteItemInArray = (item) => {
    setSelectedItems((items) => { return items.filter((itemArray) => itemArray !== item) })
  }

  const checkIsSelected = (item) => {
    return SelectedItems.includes(item)
  }

  const detectSelectedProjects = () => {
    // Определяем поле для поиска на основе роли
    const roleField = role === StaffPositionEnum.MANAGER ? 'assigned_managers' : 'assigned_developers'
    // Фильтруем проекты, где сотрудник назначен
    const result = projects.filter(project => project[roleField].includes(userId))
    setSelectedItems(result)
  }

  const detectSelectedStaff = () => {
    // Найти выбранного сотрудника по его ID
    const selectedStaff = staff.find(staff => staff.user_id === userId)

    // Если сотрудник не найден или assignments пустой, вернуть пустой массив
    if (!selectedStaff || !selectedStaff.assignments) {
      return
    }

    // Создать список сотрудников из assignments, сопоставляя их по user_id
    const assignedStaff = selectedStaff.assignments.map(assignment => {
      const staffResult = staff.find(staff => staff.user_id === assignment.user_id)
      return (staffResult || null)
    })

    // Фильтровать null значения (если какой-то сотрудник отсутствует в staffList)
    setSelectedItems(assignedStaff.filter(staff => staff !== null))
  }

  useEffect(() => {
    selectedProjects(SelectedItems)
  }, [SelectedItems])

  useEffect(() => {
    if (projects) {
      detectSelectedProjects()
    } else {
      detectSelectedStaff()
    }
  }, [role])

  return (
        <div className="main-multiselect-project">
            <div className="picker-multiselect-project-profile" onClick={changeVisibleArray}>
                {SelectedItems.length === 0 ? (projects ? <p>Проект</p> : <p>Сотрудник</p>) : ''}
                <div className="selected-items-project">
                {SelectedItems && SelectedItems.map((item, index) => (
                    <div className="item-multiselect-project" key={index}>
                        <img className='item-multiselect-project-icon' src={projects ? DefaultProjectIcon : item.avatar}/>
                        <p>{projects ? item.name : `${item.firstname} ${item.lastname}`}</p>
                        <img src={DeleteSelectItem} className='item-multiselect-project-delete' onClick={(e) => {
                          e.stopPropagation()
                          DeleteItemInArray(item)
                        }}/>
                    </div>
                ))}
                </div>
                <img className='expand-arrow' src={ArrowSelectIcon} />
            </div>
            {VisibleArray &&
            <div className="array-multiselect-items">
                { projects
                  ? projects.map((item, index) => (
                    <div className="item-array-multiselect" key={index} onClick={() => AddItemInArray(item)}>
                        <p>{item.name}</p>
                        <input type='checkbox' className={'item-checkbox' + (checkIsSelected(item) ? ' checked' : '')} checked={checkIsSelected(item)} />
                    </div>
                  ))
                  : staff.filter(staff => staff.user_id !== userId && staff.role !== StaffPositionEnum.MANAGER).map((item, index) => (
                    <div className="item-array-multiselect" key={index} onClick={() => AddItemInArray(item)}>
                        <img className='item-multiselect-project-icon' src={projects ? DefaultProjectIcon : item.avatar}/>
                        <p>{`${item.firstname} ${item.lastname}`}</p>
                        <input type='checkbox' className={'item-checkbox' + (checkIsSelected(item) ? ' checked' : '')} checked={checkIsSelected(item)} />
                    </div>
                  ))
            }
            </div>}
        </div>
  )
}

export default AssignProjects
