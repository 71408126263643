import DefaultProjectIcon from '../../icons/default_project_icon.svg'
import HomePinIcon from '../../icons/icon_home_pin.svg'
import { getMonth, getYear, getDate } from 'date-fns'
import '../../css/block_day_report.css'
import { getHourString, getMinuteString } from '../../jsx/Helpers/TimeExtensions'
import { onCopyReport } from '../Helpers/ReportExtensions'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { SvgIcon } from '@mui/material'
import TextView from '../../components/TextView'

const BlockDayReport = ({ id, date, projects, workPlace, isWorkHome, projectsList }) => {
  const namesMonths = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря']

  const retrieveProjectName = (item) => {
    const projectItem = projectsList ? projectsList.find(project => project.id === item.project_id) : 'null'
    return projectItem ? projectItem.name : 'Проект не выбран'
  }

  const handleCopyAllReportInfo = () => {
    onCopyReport(projects.map(item => ({ ...item, text_report: item.project_progress_text })), projectsList)
  }

  const handleCopyBlockReportInfo = (item) => {
    onCopyReport([{ ...item, text_report: item.project_progress_text }], null, retrieveProjectName(item))
  }

  return (
        <div className="day-report">
            <div className="header-day-report">
                <p>{`${getDate(date)} ${namesMonths[getMonth(date)]} ${getYear(date)}`}</p>
                { isWorkHome && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={HomePinIcon} style={{ paddingRight: '8px' }} alt="Стрелочка" />
                              <p className="text-project-day-report"> Работал из дома </p>
                    </div>
                ) }
                <div className='copy-report-block' style={{ height: '14px' }} onClick={handleCopyAllReportInfo}>Скопировать весь отчет</div>
            </div>
            <div className="main-day-report">
                {projects.map((item, index) => (
                    <div key={index} className="project-day-report">
                        <div className="name-project-day-report">
                            <div className="name-project">
                                <img src={DefaultProjectIcon}/>
                                <p>{retrieveProjectName(item)}</p>
                                <SvgIcon className='copy-report-block' alt="copy" component={ContentCopyIcon} style={{ marginLeft: '12px', width: '18px', height: '18px', padding: '4px' }} onClick={() => handleCopyBlockReportInfo(item)}></SvgIcon>
                            </div>
                            <p>{ getHourString(item.hours_work) + ' ' + (item.minutes_work === 0 ? '' : getMinuteString(item.minutes_work)) }</p>
                        </div>
                        <TextView text={item.project_progress_text} readOnly={true} />
                    </div>
                ))}
            </div>
            {
                isWorkHome && (
                    <div className="footer-day-report">
                        <p className="text-project-day-report">{workPlace}</p>
                    </div>)
            }
            {/* <div className="footer-day-report">
                <p className="text-project-day-report">{work_place + "  " + isWorkHome}</p>
            </div> */}
        </div>
  )
}

export default BlockDayReport
