import calendarImage from '../../icons/calendar_icon.svg'
import leftButtonImage from '../../icons/icon_left.svg'
import rightButtonImage from '../../icons/icon_right.svg'
import profileButtonImage from '../../icons/profile_img_icon.svg'
import DetailedReviewIcon from '../../icons/detail_review_icon.svg'
import MissedReportsIcon from '../../icons/missed_reports_icon.svg'
import '../../css/main.css'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { GlobalStateContext } from '../GlobalStateContext.jsx'
import Toolbar from '../../jsx/Toolbar.jsx'
import Profile from '../../jsx/Profile.jsx'
import Calendar from '../../jsx/WeeksCalendar/Calendar.jsx'
import BriefReviewPage from './BriefReviewPage.jsx'
import DetailedReviewPage from './DetailedReviewPage.jsx'
import { sortProjectsByRole } from '../Helpers/ProjectsExtensions.js'
import axios from 'axios'
import { getYear } from 'date-fns'

function Main () {
  const namesMonths = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря']

  const [profile, setVisibleProfile] = useState(false)
  const [calendar, setVisibleCalendar] = useState(false)
  const [TypeReview, setTypeReview] = useState({ type: false, text: 'Подробный обзор' })
  const [week, setWeek] = useState({ name_week: '', select_week: new Date() })
  const childRef = useRef()
  const textRef = useRef(null)
  const profileRef = useRef(null)
  const profileRef1 = useRef(null)
  const calendarRef = useRef(null)
  const calendarRef1 = useRef(null)
  const [modalIsOpen, setModalIsOpen] = useState(true)
  const [StartDate, setStartDate] = useState('')
  const [EndDate, setEndDate] = useState('')
  const [MissedReportsDates, setMissedReportsDates] = useState([])
  const { getProfileInfo, getCurrentBackendUrl } = useContext(GlobalStateContext)

  const [NextWeekAvailable, setNextWeekAvailable] = useState(true)

  const [ProjectsList, setProjectsList] = useState([])

  const changeVisibleProfile = () => {
    setVisibleProfile(!profile)
  }

  const changeVisibleCalendar = () => {
    setVisibleCalendar(!calendar)
  }

  const showDetailedReview = () => {
    if (TypeReview.type === false) {
      setTypeReview({ type: true, text: 'Краткий обзор' })
    } else {
      setTypeReview({ type: false, text: 'Подробный обзор' })
    }
  }

  const changeWeeklyReview = (weekName, selectWeek, selectedYear) => {
    setWeek({ name_week: weekName, select_week: selectWeek })
    const [startDateStr, endDateStr] = weekName.split(' - ')
    const a = startDateStr + ' ' + selectedYear
    const b = endDateStr + ' ' + selectedYear

    convertDateString(a + ' - ' + b)

    // console.log("current selected week = " + StartDate + " - " + EndDate);
  }

  const MinusWeek = () => {
    childRef.current.changeWeekThroughArrow('-')
  }

  function convertDateString (dateString) {
    // Маппинг русских названий месяцев на числа
    const monthsMap = {
      Января: '01',
      Февраля: '02',
      Марта: '03',
      Апреля: '04',
      Мая: '05',
      Июня: '06',
      Июля: '07',
      Августа: '08',
      Сентября: '09',
      Октября: '10',
      Ноября: '11',
      Декабря: '12'
    }

    // Разбиваем строку на две даты
    const [startDateStr, endDateStr] = dateString.split(' - ')

    // Функция для преобразования даты в формат YYYY-MM-DD
    function formatDate (dateStr) {
      const [day, month, year] = dateStr.split(' ') // Разбиваем по пробелу
      const monthNumber = monthsMap[month] // Преобразуем месяц через маппинг
      return `${year}-${monthNumber}-${day.padStart(2, '0')}` // Возвращаем в формате YYYY-MM-DD
    }

    // Преобразуем обе даты
    const startDate = formatDate(startDateStr)
    const endDate = formatDate(endDateStr)

    setStartDate(startDate)
    setEndDate(endDate)

    // console.log("Try to format start and end date for request api: " + StartDate + EndDate);

    return { startDate, endDate }
  }

  useEffect(() => {
    getAllProjects()
  }, [])

  const getAllProjects = async () => {
    await axios.get(`${getCurrentBackendUrl()}/api/projects`)
      .then((response) => {
      // console.log("getProjects: ", response.data);
        setProjectsList(sortProjectsByRole(Number(getProfileInfo().id), Number(getProfileInfo().role), response.data))
      })
      // eslint-disable-next-line n/handle-callback-err
      .catch((error) => {
      // console.log('Error:', error);
      })
  }

  const PlusWeek = () => {
    childRef.current.changeWeekThroughArrow('+')
  }

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const handleClickOutside = (event) => {
    if (profileRef.current && !profileRef.current.contains(event.target) && !profileRef1.current.contains(event.target)) {
      setVisibleProfile(false)
    }

    if (calendarRef.current && !calendarRef.current.contains(event.target) && !calendarRef1.current.contains(event.target)) {
      setVisibleCalendar(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const adjustFontSize = () => {
      const element = textRef.current
      const initialFontSize = 18
      let currentFontSize = initialFontSize

      element.style.fontSize = `${initialFontSize}px`
      element.style.overflow = 'hidden'
      while (element.clientHeight > 28) {
        currentFontSize -= 1
        element.style.fontSize = `${currentFontSize}px`
      }

      element.style.whiteSpace = 'normal'
    }

    adjustFontSize()
    window.addEventListener('resize', adjustFontSize)

    return () => {
      window.removeEventListener('resize', adjustFontSize)
    }
  }, [week])

  const getMissedReportsDates = (e) => {
    // console.log("получен список дат, когда не было отчета: " + e.join(", "));
    setMissedReportsDates(e)
  }

  const updateNextWeekAvailableDates = (available) => {
    setNextWeekAvailable(available)
  }

  return (
    <div className='main-wrapper'>
      <Toolbar page={'weekly_reports'}/>
      <div className="content-report">
        <div className="header-report">
          <div className="header-report-item" style={{ gap: '8px', backgroundColor: '#007EC5', cursor: 'default' }} onClick={showDetailedReview}>
            <img src={DetailedReviewIcon} />
            <p>{TypeReview.text}</p>
          </div>
          <div className="header-report-item">
          <p>{`${new Date().getDate()} ${namesMonths[new Date().getMonth()]} ${getYear(new Date())}`}</p>
          </div>
          <div className="header-report-item" style={{ padding: '0px 10px', justifyContent: 'space-between' }}>
            <img src={leftButtonImage} onClick={MinusWeek} />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px', cursor: 'default' }} onClick={changeVisibleCalendar} ref={calendarRef1}>
              <img src={calendarImage} />
              <p style={{ margin: '0' }} ref={textRef}>{week.name_week}</p>
            </div>
            { NextWeekAvailable === true && <img src={rightButtonImage} onClick={PlusWeek} />}
          </div>
          <div className="header-report-item" style={{ background: 'transparent' }}>
            <span className="profile-box">
              <img src={ getProfileInfo().avatar ? (getProfileInfo().avatar) : profileButtonImage } onClick={changeVisibleProfile} ref={profileRef1}/>
            </span>
          </div>
        </div>
        <div className="main-report" style={{ alignItems: 'normal' }}>
          <div className={profile === false ? 'profile-invisible' : 'profile-visible'} ref={profileRef}>
            <Profile closeProfile={changeVisibleProfile}/>
          </div>
          <div className={calendar === false ? 'calendar-invisible' : 'calendar-visible'} ref={calendarRef}>
            <Calendar changeWeekly={changeWeeklyReview} ref={childRef} nextWeekAvailable={updateNextWeekAvailableDates} />
          </div>
          { MissedReportsDates.length > 0 && <div className="notification-missed-reports">
                    <img src={MissedReportsIcon} />
                    <p>Внимание! Не созданы отчеты за { MissedReportsDates.join('; ') }</p>
                </div>}
          {TypeReview.type
            ? <DetailedReviewPage dates={week.select_week} startDate={StartDate} endDate={EndDate} missedReportsDates={getMissedReportsDates} projectsList={ProjectsList}/>
            : <BriefReviewPage dates={week.select_week} startDate={StartDate} endDate={EndDate} missedReportsDates={getMissedReportsDates} projectsList={ProjectsList}/>}
        </div>
      </div>
    </div>

  )
}

export default Main
