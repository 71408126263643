/* eslint-disable multiline-ternary */
import React from 'react'
import '../css/clickable_links_text.css'

const ClickableLinksText = ({ text, customStyle }) => {
  const makeLinksClickable = (text) => {
    if (text.length === 0) return
    return text.replace(
      /(https?:\/\/[^\s]+)/g,
      '<a href="$1" target="_blank" rel="noopener noreferrer" style="color: rgb(35, 171, 255);">$1</a>'
    )
  }

  return <p className={'text' + (customStyle ? ' ' + customStyle : '')} dangerouslySetInnerHTML={{ __html: makeLinksClickable(text) }} />
}

export default ClickableLinksText
