export const StaffPositionEnum = {
  DEVELOPER: 1,
  INTERN: 2,
  DESIGNER: 3,
  MANAGER: 4,
  ADMIN: 5,
  DIRECTOR: 6,
  SUPER_ADMIN: 7
}

export const convertStaffPositionToString = (position) => {
  switch (position) {
    case StaffPositionEnum.DEVELOPER:
      return 'Разработчик'
    case StaffPositionEnum.INTERN:
      return 'Интерн'
    case StaffPositionEnum.DESIGNER:
      return 'Дизайнер'
    case StaffPositionEnum.MANAGER:
      return 'Менеджер'
    case StaffPositionEnum.ADMIN:
      return 'Администратор'
    case StaffPositionEnum.DIRECTOR:
      return 'Директор'
    case StaffPositionEnum.SUPER_ADMIN:
      return 'Супер-администратор'
  }
}
