import '../../css/detailed_review_page.css'
import BlockDayReport from './BlockDayReport'
import DefaultProjectIcon from '../../icons/default_project_icon.svg'
import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { GlobalStateContext } from '../GlobalStateContext'
import { findMissingReportDates } from '../Helpers/ReportExtensions'

const DetailedReviewPage = ({ dates, startDate, endDate, missedReportsDates, isModalReport, isExternalReport, externalReport, id, projectsList }) => {
  const [ReportList, setReportList] = useState([])

  const { getCurrentBackendUrl, getProfileInfo } = useContext(GlobalStateContext)

  useEffect(() => {
    if ((startDate || endDate) && !isExternalReport) {
      getReportsList()
    }
    // console.log("Start date: ", startDate, "End date: ", endDate, "dates: ", dates);
  }, [dates])

  useEffect(() => {
    if (isExternalReport && externalReport !== undefined) {
      setReportList(transformReportsData(Array.isArray(externalReport) ? externalReport : Array.of(externalReport)).sort((a, b) => b.date - a.date))
    }
  }, [externalReport])

  useEffect(() => {
    const missingDates = findMissingReportDates(startDate, endDate, ReportList)
    // console.log("Отсутствуют отчеты за следующие даты:", missingDates, ReportList);
    if (missedReportsDates.length !== 0) missedReportsDates(missingDates)
  }, [ReportList])

  const getReportsList = async () => {
    await axios.get(`${getCurrentBackendUrl()}/api/reports?creator_id=${id || getProfileInfo().id}&end_date=${endDate}&start_date=${startDate}`, {
    })
      .then((response) => {
        // console.log('Success parse data for weekly report:', transformReportsData(response.data));
        setReportList(transformReportsData(response.data).sort((a, b) => b.date - a.date))
      })
      // eslint-disable-next-line n/handle-callback-err
      .catch((error) => {
        // console.log('Error:', error);
      })
  }

  function transformReportsData (serverReports) {
    return serverReports.map(report => {
      // Преобразуем дату в формат Date
      const reportDate = new Date(report.date)

      // Преобразуем проекты, добавляя изображение и название проекта
      const projects = report.item_reports.map(item => ({
        img_project: DefaultProjectIcon,
        project_id: item.project_id,
        project_progress_text: item.text_report,
        hours_work: item.hours_work,
        minutes_work: item.minutes_work
      }))

      // Суммируем все часы работы по проектам в этом отчете
      const totalHours = report.item_reports.reduce((total, item) => total + item.hours_work, 0)
      const totalMinutes = report.item_reports.reduce((total, item) => total + item.minutes_work, 0)

      // Место работы (из work_home)
      const workPlace = report.work_home_reason || 'Не указано' // Если work_home пустое

      const isWorkHome = report.is_work_home

      return {
        id: report.id,
        date: reportDate,
        projects,
        is_work_home: isWorkHome,
        work_place: workPlace,
        time: totalHours + Math.round(totalMinutes / 60)
      }
    })
  }

  return (
        <div className={isModalReport ? 'main-report-modal-detailed-review' : 'main-report-detailed-review'}>
            <div className={isModalReport ? 'modal-container-detailed-review' : 'container-detailed-review'}>
{ ReportList.map((item, index) => (
                    <BlockDayReport key={index} id={item.id} date={item.date} projects={item.projects} workPlace={item.work_place} isWorkHome={item.is_work_home} projectsList={projectsList}/>
))}
            </div>
        </div>
  )
}

export default DetailedReviewPage
