import ArrowSelectIcon from '../../icons/icon_select_arrow.svg'
import React, { useState, useEffect } from 'react'
import '../../css/summary_day_report_filter.css'
import IconAll from '../../icons/emoji_boy.svg'
import IconHome from '../../icons/icon_home.svg'
import IconOffice from '../../icons/icon_office.svg'
import IconNoReport from '../../icons/icon_no_report.svg'

const SummaryDayReportFilter = ({ filters, onSelectFilter, showIcons }) => {
  const [SelectedFilter, setSelectedFilter] = useState(0)
  const [VisibleArray, setVisibleArray] = useState(false)
  const filterIcons = [IconAll, IconHome, IconOffice, IconNoReport]

  const changeVisibleArray = () => {
    setVisibleArray(!VisibleArray)
  }

  const handleSelectFilter = (index) => {
    setSelectedFilter(index)
    onSelectFilter(index)
  }

  useEffect(() => {
    onSelectFilter(SelectedFilter)
  }, [SelectedFilter])

  return (
        <div className="main-multiselect">
            <div className="picker-multi-select-filter" onClick={changeVisibleArray}>
                <div style={{ display: 'flex', gap: '6px', borderRadius: '4px', alignItems: 'center', maxWidth: '13vw', backgroundColor: '#3F3F3F' }}>
                  {
                    showIcons === true && <img height={24} width={24} style={{ marginLeft: '8px' }} src={filterIcons[SelectedFilter]}></img>
                  }
                <span className='selected-filter'>{filters && filters[SelectedFilter]}</span>
                </div>
                <div className={'selected-items-filter' + (VisibleArray === true ? '' : '-hidden')}>
                {(VisibleArray === true) && filters.map((item, index) => (
                    <div className={'item-multiselect' + (index === SelectedFilter ? '-selected' : '')} key={index} onClick={() => handleSelectFilter(index)}>
                        <img height={24} width={24} src={filterIcons[index]}/>
                        <p>{item}</p>
                    </div>
                ))}
                </div>
                <img className='expand-arrow' src={ArrowSelectIcon} />
            </div>
            {/* {VisibleArray &&
            <div className="array-multiselect-items">
                {SelectedFilter > 0 && filters.map((filter, index) => (
                    <div className="item-array-multiselect" key={index} onClick={() => handleSelectFilter(index)}>
                        <p>{filter}</p>
                    </div>
                ))}
            </div>} */}
        </div>
  )
}

export default SummaryDayReportFilter
