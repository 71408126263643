// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editorToolbar {
    font-family: "RobotoRegular";
    border-radius: 10px;
    background: transparent !important;
    border: 0px !important;
    border-bottom: 0.5px solid #0079C5 !important;
    padding: 14px 0 8px 8px !important;
    margin: -17px;
  }

  .editorWrapper {
    padding: 1rem;
  }

  .read {
    padding: 0;
    margin: -6px;
  }

  .edit {
    border: 0.5px solid #0079C5;
    background: #2D2D2D;
  }

  .editor {
    font-family: "RobotoRegular";
    border-radius: 10px;  
    padding: 1rem; 
    color: white;
  }

  .editorButton {
    background: transparent !important;
  }

  .rdw-option-wrapper.rdw-option-active {
    background: rgb(212, 212, 212);
  }

  .rdw-option-wrapper.rdw-option-active {
    background: #18a7ff !important;
    border:  1px solid transparent !important;
  }
 
  .rdw-link-decorator-wrapper a {
    color: rgb(35, 171, 255) !important;
  }`, "",{"version":3,"sources":["webpack://./src/css/custom_text_viewer.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,mBAAmB;IACnB,kCAAkC;IAClC,sBAAsB;IACtB,6CAA6C;IAC7C,kCAAkC;IAClC,aAAa;EACf;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,UAAU;IACV,YAAY;EACd;;EAEA;IACE,2BAA2B;IAC3B,mBAAmB;EACrB;;EAEA;IACE,4BAA4B;IAC5B,mBAAmB;IACnB,aAAa;IACb,YAAY;EACd;;EAEA;IACE,kCAAkC;EACpC;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,8BAA8B;IAC9B,yCAAyC;EAC3C;;EAEA;IACE,mCAAmC;EACrC","sourcesContent":[".editorToolbar {\n    font-family: \"RobotoRegular\";\n    border-radius: 10px;\n    background: transparent !important;\n    border: 0px !important;\n    border-bottom: 0.5px solid #0079C5 !important;\n    padding: 14px 0 8px 8px !important;\n    margin: -17px;\n  }\n\n  .editorWrapper {\n    padding: 1rem;\n  }\n\n  .read {\n    padding: 0;\n    margin: -6px;\n  }\n\n  .edit {\n    border: 0.5px solid #0079C5;\n    background: #2D2D2D;\n  }\n\n  .editor {\n    font-family: \"RobotoRegular\";\n    border-radius: 10px;  \n    padding: 1rem; \n    color: white;\n  }\n\n  .editorButton {\n    background: transparent !important;\n  }\n\n  .rdw-option-wrapper.rdw-option-active {\n    background: rgb(212, 212, 212);\n  }\n\n  .rdw-option-wrapper.rdw-option-active {\n    background: #18a7ff !important;\n    border:  1px solid transparent !important;\n  }\n \n  .rdw-link-decorator-wrapper a {\n    color: rgb(35, 171, 255) !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
