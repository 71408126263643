export function convertDateString (dateString) {
  // Маппинг русских названий месяцев на числа
  const monthsMap = {
    Января: '01',
    Февраля: '02',
    Марта: '03',
    Апреля: '04',
    Мая: '05',
    Июня: '06',
    Июля: '07',
    Августа: '08',
    Сентября: '09',
    Октября: '10',
    Ноября: '11',
    Декабря: '12'
  }

  // Разбиваем строку на две даты
  const [startDateStr, endDateStr] = dateString.split(' - ')

  // Функция для преобразования даты в формат YYYY-MM-DD
  function formatDate (dateStr) {
    const [day, month, year] = dateStr.split(' ') // Разбиваем по пробелу
    const monthNumber = monthsMap[month] // Преобразуем месяц через маппинг
    return `${year}-${monthNumber}-${day.padStart(2, '0')}` // Возвращаем в формате YYYY-MM-DD
  }

  // Преобразуем обе даты
  const startDate = formatDate(startDateStr)
  const endDate = formatDate(endDateStr)

  // setStartDate(startDate);
  // setEndDate(endDate);

  // console.log("Try to format start and end date for request api: " + startDate + endDate);

  return { startDate, endDate }
}

export function formatDateComma (dateString) {
  // Разбиваем строку по символу '-'
  const [year, month, day] = dateString.split('-')
  // Формируем строку в формате 'DD.MM.YYYY'
  return `${day}.${month}.${year}`
}

export function getWorkDays () {
  const daysOfWeek = []
  const today = new Date()
  const currentDay = today.getDay() // День недели (0 - воскресенье, 1 - понедельник и т.д.)

  // Определяем, сколько дней нужно отнять, чтобы получить понедельник текущей недели
  const daysToMonday = currentDay === 0 ? 6 : currentDay - 1

  // Получаем понедельник текущей недели
  const monday = new Date(today)
  monday.setDate(today.getDate() - daysToMonday)

  // Форматирование даты в строку "YYYY-MM-DD"
  function formatDate (date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  // Добавляем рабочие дни от сегодняшнего дня назад к понедельнику текущей недели
  for (let i = 0; i <= (currentDay === 0 ? 4 : currentDay - 1); i++) {
    const workDay = new Date(today)
    workDay.setDate(today.getDate() - i)
    daysOfWeek.push(formatDate(workDay))
  }

  // Если рабочих дней меньше 5, добавляем дни из предыдущей недели
  if (daysOfWeek.length < 5) {
    const lastFriday = new Date(monday)
    lastFriday.setDate(monday.getDate() - 3) // Получаем пятницу прошлой недели

    for (let i = 0; daysOfWeek.length < 5; i++) {
      const previousWorkDay = new Date(lastFriday)
      previousWorkDay.setDate(lastFriday.getDate() - i)
      daysOfWeek.push(formatDate(previousWorkDay)) // Добавляем в конец списка
    }
  }

  return daysOfWeek
}

export function fillMissingReports (reports, workDays) {
  // Шаг 1: Получаем рабочие дни недели
  // console.log("Work days: ", workDays);

  // Шаг 2: Создаём объект для быстрого поиска по дате
  const reportsByDate = {}
  reports.forEach((report) => {
    reportsByDate[report.date] = report
  })

  // Шаг 3: Создаём массив для хранения дат с отсутствующими отчётами
  const missingReportDates = []

  // Шаг 4: Создаём итоговый массив с рабочими днями
  const completeReports = workDays.map((day) => {
    if (reportsByDate[day]) {
      // Если отчёт на эту дату уже существует, добавляем его в итоговый массив
      return reportsByDate[day]
    } else {
      // Если отчёта нет, создаём пустой отчёт с указанием только даты
      missingReportDates.push(day) // Добавляем дату, для которой нет отчёта
      return {
        id: null, // ID можно оставить null, если его нет
        item_reports: [
          {
            name_project: null,
            hours_work: null,
            minutes_work: null,
            text_report: null
          }
        ], // Пустой массив отчётов
        creator_id: null, // ID создателя по умолчанию
        date: day, // Указываем только дату
        is_work_home: false, // Поле по умолчанию
        work_home_reason: '' // Поле можно оставить пустым
      }
    }
  })

  // Шаг 5: Добавляем отчёты за выходные дни, если они есть
  reports.forEach((report) => {
    const reportDate = new Date(report.date)
    const dayOfWeek = reportDate.getDay() // Получаем день недели (0 - воскресенье, 6 - суббота)

    // Проверяем, выходной ли день и есть ли такой день в итоговом массиве
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      // Проверяем, не был ли этот отчет уже добавлен в completeReports
      const existingReportIndex = completeReports.findIndex((r) => r.date === report.date)
      if (existingReportIndex === -1) {
        // Добавляем отчет за выходной день в итоговый массив
        completeReports.push(report)
      }
    }
  })

  // Шаг 6: Сортируем итоговый массив по дате (от самой свежей к самой старой)
  completeReports.sort((a, b) => new Date(b.date) - new Date(a.date))

  // Возвращаем и итоговый массив с отчётами, и список дат с отсутствующими отчётами
  return { completeReports: completeReports.slice(0, 7), missingReportDates }
}
