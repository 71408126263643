import ArrowIcon from '../../icons/icon_arrow.svg'
import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react'
import { getMonth } from 'date-fns'
import '../../css/select_month.css'

const SelectMonth = forwardRef((props, ref) => {
  const MonthArray = [{ id: 0, name: 'Январь' },
    { id: 1, name: 'Февраль' },
    { id: 2, name: 'Март' },
    { id: 3, name: 'Апрель' },
    { id: 4, name: 'Май' },
    { id: 5, name: 'Июнь' },
    { id: 6, name: 'Июль' },
    { id: 7, name: 'Август' },
    { id: 8, name: 'Сентябрь' },
    { id: 9, name: 'Октябрь' },
    { id: 10, name: 'Ноябрь' },
    { id: 11, name: 'Декабрь' }]
  const [currentMonth, setCurrentMonth] = useState(getMonth(new Date()))
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const nowMonth = new Date().getMonth()
  const [isVisible, setIsVisible] = useState(false)
  const elementRef = useRef(null)
  const elementRef1 = useRef(null)

  const changeMonthWithoutVisible = (month) => {
    props.setMonth(month.id)
    setCurrentMonth(month.id)
  }

  const changeCurrentYear = (year) => {
    setCurrentYear(year)
  }

  useImperativeHandle(ref, () => ({
    changeMonthWithoutVisible,
    changeCurrentYear
  }))

  const changeMonth = (month) => {
    props.setMonth(month.id)
    setCurrentMonth(month.id)
    VisibleArray()
  }

  const VisibleArray = () => {
    setIsVisible(!isVisible)
  }

  const handleClickOutside = (event) => {
    if (elementRef.current && !elementRef.current.contains(event.target) && !elementRef1.current.contains(event.target)) {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
        <div className="select-month-container">
            <div className="picker-month" ref={elementRef1} onClick={VisibleArray}>
                <p>{MonthArray[currentMonth].name}</p>
                <img src={ArrowIcon} />
            </div>
            {isVisible &&
            <div className="array-months" ref={elementRef}>
                {MonthArray.filter(month => (currentYear === new Date().getFullYear()) ? month.id <= nowMonth : true).map((item, index) => (
                    <p className="item-month" key={index} onClick={() => changeMonth(item)}>{item.name}</p>
                ))}
            </div>}
        </div>
  )
})

export default SelectMonth
