import { StaffPositionEnum } from '../Helpers/StaffExtensions'

export const sortProjectsByRole = (id, role, projectsList) => {
  if ([StaffPositionEnum.ADMIN, StaffPositionEnum.DIRECTOR, StaffPositionEnum.SUPER_ADMIN].includes(role)) {
    return projectsList
  } else {
    return projectsList.filter(
      (project) => project.assigned_developers.includes(id) || project.assigned_managers.includes(id)
    )
  }
}
