// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-delete-modal-text {
    color: white;
    font-family: "RobotoRegular";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.top-modal-bar-delete {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.modal-title-delete {
    color: #EDEDED;
    font-size: 20px;
    font-family: "RobotoMedium";
    margin: 0px;
}`, "",{"version":3,"sources":["webpack://./src/css/profile_delete_modal.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,4BAA4B;IAC5B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,2BAA2B;IAC3B,WAAW;AACf","sourcesContent":[".profile-delete-modal-text {\n    color: white;\n    font-family: \"RobotoRegular\";\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n}\n\n.top-modal-bar-delete {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 24px;\n}\n\n.modal-title-delete {\n    color: #EDEDED;\n    font-size: 20px;\n    font-family: \"RobotoMedium\";\n    margin: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
